// Treezor values:
export const KYC_REVIEW_NONE = 0;
export const KYC_REVIEW_PENDING = 1;
export const KYC_REVIEW_VALIDATED = 2;
export const KYC_REVIEW_REFUSED = 3;

const KYCReview = {
  KYC_REVIEW_NONE,
  KYC_REVIEW_PENDING,
  KYC_REVIEW_VALIDATED,
  KYC_REVIEW_REFUSED,
};
export default KYCReview;
