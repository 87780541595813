export const KYC_DOCUMENT_REVIEW_PENDING = 1;
export const KYC_DOCUMENT_REVIEW_VALIDATED = 2;
export const KYC_DOCUMENT_REVIEW_REFUSED = 3;
export const KYC_DOCUMENT_REVIEW_REPLACED = 4;
export const KYC_DOCUMENT_REVIEW_CANCELED = 5;

const KYCDocumentReview = {
  KYC_DOCUMENT_REVIEW_PENDING,
  KYC_DOCUMENT_REVIEW_VALIDATED,
  KYC_DOCUMENT_REVIEW_REFUSED,
  KYC_DOCUMENT_REVIEW_REPLACED,
  KYC_DOCUMENT_REVIEW_CANCELED,
};

export default KYCDocumentReview;
