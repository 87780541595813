const BeneficiaryTypes = [
  {
    code: 'provider',
    label: 'beneficiary.type.provider',
  },
  {
    code: 'client',
    label: 'beneficiary.type.client',
  },
  {
    code: 'user',
    label: 'beneficiary.type.user',
  },
  {
    code: 'company',
    label: 'beneficiary.type.me',
  },
];

export default BeneficiaryTypes;
