export const KYC_LEVEL_NONE = 0;
export const KYC_LEVEL_LIGHT = 1;
export const KYC_LEVEL_REGULAR = 2;
export const KYC_LEVEL_STRONG = 3;
export const KYC_LEVEL_REFUSED = 4;

const KYCLevel = {
  KYC_LEVEL_NONE,
  KYC_LEVEL_LIGHT,
  KYC_LEVEL_REGULAR,
  KYC_LEVEL_STRONG,
  KYC_LEVEL_REFUSED,
};

export default KYCLevel;
