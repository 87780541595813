import { map, filter } from 'lodash';

import CountryList from '../assets/countryList.json';

const getHighRiskCountryCodes = () => {
  const countries = filter(CountryList, (c) => c.risk === true);
  return map(countries, (c) => c.alpha2);
};

const getEuropeanUnionCountryCodes = () => {
  const countries = filter(CountryList, (c) => c.EU === true);
  return map(countries, (c) => c.alpha2);
};

const getOutsideEuropeCountryCodes = () => {
  const countries = filter(CountryList, (c) => c.EU === false);
  return map(countries, (c) => c.alpha2);
};
// Country codes: TODO: remove this one
export const CountryCodes = getHighRiskCountryCodes();
// High risk country codes
export const HighRiskCountryCodes = getHighRiskCountryCodes();

// Europe country codes
export const EuropeCountryCodes = getEuropeanUnionCountryCodes();

// outside Europe country codes
export const OutsideEuropeCountryCodes = getOutsideEuropeCountryCodes();
