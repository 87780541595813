export const UNBLOCK = 0;
export const BLOCK = 1;
export const LOST = 2;
export const STOLEN = 3;

const CardStatus = {
  UNBLOCK,
  BLOCK,
  LOST,
  STOLEN,
};

export default CardStatus;
