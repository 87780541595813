import BeneficiaryTypes from './BeneficiaryTypes';
import DocumentTypes from './DocumentTypes';
import KYCLevel from './KYCLevel';
import KYCReview from './KYCReview';
import KYCDocumentReview from './KYCDocumentReview';
import CardStatus from './CardStatus';

export default {
  BeneficiaryTypes,
  DocumentTypes,
  KYCLevel,
  KYCReview,
  KYCDocumentReview,
  CardStatus,
};
