const identityPrimaryList = [
  {
    label: 'treezor.identity.9',
    type: 9,
    codeBBF: 1,
  },
  {
    label: 'treezor.identity.17',
    type: 17,
    codeBBF: 2,
  },
  {
    label: 'treezor.identity.17bis',
    type: 17,
    codeBBF: 3,
  },
  {
    label: 'treezor.identity.15',
    type: 15,
    codeBBF: 4,
  },
];

const identitySecondaryList = [
  {
    label: 'treezor.identity.9',
    type: 9,
    codeBBF: 5,
  },
  {
    label: 'treezor.identity.17ter',
    type: 17,
    codeBBF: 6,
  },
  {
    label: 'treezor.identity.16',
    type: 16,
    codeBBF: 7,
  },
  {
    label: 'treezor.identity.9bis',
    type: 9,
    codeBBF: 8,
  },
];

const proofOfAddress = [
  {
    label: 'treezor.proofOfAddress.12',
    type: 12,
    codeBBF: 9,
  },
  {
    label: 'treezor.proofOfAddress.12bis',
    type: 12,
    codeBBF: 10,
  },
  {
    label: 'treezor.proofOfAddress.12ter',
    type: 12,
    codeBBF: 11,
  },
  {
    label: 'treezor.proofOfAddress.12quater',
    type: 12,
    codeBBF: 12,
  },
  {
    label: 'treezor.proofOfAddress.12quinquies',
    type: 12,
    codeBBF: 13,
  },
  {
    label: 'treezor.proofOfAddress.12sexies',
    type: 12,
    codeBBF: 14,
  },
  {
    label: 'treezor.proofOfAddress.13',
    type: 13,
    codeBBF: 15,
  },
  {
    label: 'treezor.proofOfAddress.14',
    type: 14,
    codeBBF: 16,
  },
];

const soleTraderRegistration = [
  {
    label: 'treezor.companyCredentials.4',
    type: 4,
    codeBBF: 17,
  },
  {
    label: 'treezor.companyCredentials.4bis',
    type: 4,
    codeBBF: 18,
  },
];

const companyRegistration = [
  {
    label: 'treezor.companyCredentials.4',
    type: 4,
    codeBBF: 19,
  },
];

const independantRegistration = [
  {
    label: 'treezor.companyCredentialsIndependant.4',
    type: 4,
    codeBBF: 20,
  },
  {
    label: 'treezor.companyCredentialsIndependant.4bis',
    type: 4,
    codeBBF: 21,
  },
  {
    label: 'treezor.companyCredentialsIndependant.4ter',
    type: 4,
    codeBBF: 22,
  },
];

const companyRegistrationSecondary = [
  {
    label: 'treezor.companyRegistrationOfficialPaper.19',
    type: 19,
    codeBBF: 23,
  },
];

const treezorDocumentTypes = [
  {
    label: 'treezor.policeRecord',
    type: 2,
    codeBBF: 24,
  },
  {
    label: 'CV',
    type: 6,
    codeBBF: 25,
  },
  {
    label: 'treezor.swornStatement',
    type: 7,
    codeBBF: 26,
  },
  {
    label: 'treezor.turnover',
    type: 8,
    codeBBF: 27,
  },
  {
    label: 'treezor.bankIdentityStatement',
    type: 11,
    codeBBF: 28,
  },
  {
    label: 'treezor.ProxyGrantingEmployee',
    type: 18,
    codeBBF: 29,
  },
  {
    label: 'treezor.OfficialTaxCertificate',
    type: 20,
    codeBBF: 30,
  },
  {
    label: 'treezor.employeePaymentNotice',
    type: 21,
    codeBBF: 31,
  },
  {
    label: 'treezor.userBankStatement',
    type: 22,
    codeBBF: 32,
  },
  {
    label: 'treezor.businessLegalStatus',
    type: 23,
    codeBBF: 33,
  },
  {
    label: 'treezor.taxStatement',
    type: 24,
    codeBBF: 34,
  },
  {
    label: 'treezor.exemptionStatement',
    type: 25,
    codeBBF: 35,
  },
  {
    label: 'treezor.livenessResult',
    type: 26,
    codeBBF: 36,
  },
  {
    label: 'treezor.healthInsuranceCard',
    type: 27,
    codeBBF: 37,
  },
];

export default {
  identityPrimaryList,
  identitySecondaryList,
  proofOfAddress,
  soleTraderRegistration,
  independantRegistration,
  companyRegistration,
  companyRegistrationSecondary,
  treezorDocumentTypes,
};
