import {
  KYC_LEVEL_NONE,
  KYC_LEVEL_LIGHT,
  KYC_LEVEL_REGULAR,
  KYC_LEVEL_STRONG,
  KYC_LEVEL_REFUSED,
} from './KYCLevel';

import {
  KYC_REVIEW_NONE,
  KYC_REVIEW_PENDING,
  KYC_REVIEW_REFUSED,
  KYC_REVIEW_VALIDATED,
} from './KYCReview';

export const getKYCStatus = (kycLevel, kycReview) => {
  if (kycLevel === KYC_LEVEL_NONE && kycReview === KYC_REVIEW_NONE) {
    return 1;
  }
  if (kycLevel === KYC_LEVEL_NONE && kycReview === KYC_REVIEW_PENDING) {
    return 2;
  }
  if (kycLevel === KYC_LEVEL_LIGHT && kycReview === KYC_REVIEW_REFUSED) {
    return 3;
  }
  if (kycLevel === KYC_LEVEL_REGULAR && kycReview === KYC_REVIEW_REFUSED) {
    return 3;
  }
  if (kycLevel === KYC_LEVEL_STRONG && kycReview === KYC_REVIEW_REFUSED) {
    return 4;
  }
  if (kycLevel === KYC_LEVEL_REFUSED && kycReview === KYC_REVIEW_REFUSED) {
    return 5;
  }
  if (kycLevel === KYC_LEVEL_REGULAR && kycReview === KYC_REVIEW_VALIDATED) {
    return 6;
  }
  if (kycLevel === KYC_LEVEL_REGULAR && kycReview === KYC_REVIEW_REFUSED) {
    // we can't reach that status as it is already a 3
    return 7;
  }
  if (kycLevel === KYC_LEVEL_REGULAR && kycReview === KYC_REVIEW_PENDING) {
    return 8;
  }
  return 0;
};

const KYCStaus = {
  getKYCStatus,
};

export default KYCStaus;
